import * as React from "react"
import { Link, graphql } from 'gatsby'

import Seo from "../components/seo"
import Layout from "../components/layout"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import _ from 'lodash'

const seoTitle = "グルメ＆スポット｜ TANOSHIGE【たのしげ】"
const seoDescription = "広島を中心におすすめのお店、おすすめの場所、その他お得な情報までご紹介！雑誌やテレビだけでは分からないディープな広島を知ることができます。　Youtubeにて配信中！"

const Article = (nodes) => {
  return (
    nodes.map((node) => {
      const image = getImage(node.featuredImage.node.localFile)
      const content = <>
        <div className="article-thumbnail"><GatsbyImage image={image} alt={node.title} /></div>
        <div className="article-ttl">
          <p>{node.date}</p>
          <h1>{node.title}</h1>
        </div>
      </>

      if (!node.tanoshigeExternalUrl || node.tanoshigeExternalUrl === '""') {
        return (
          <article>
            <Link to={node.uri}>
              {content}
            </Link>
          </article>
        )
      } else {
        return (
          <article>
            <a href={node.tanoshigeExternalUrl} target="_blank" rel="noreferrer">
              {content}
            </a>
          </article>
        )
      }
    })
  )
}

const SightseeingPage = ({ data }) => {
  let posts = []

  data.allWpSightseeing.edges.forEach(({ node }) => {
    posts.push(node)
  })

  let postsGourmet = _.filter(posts, function (o) { return o.sightseeingCategories.nodes[0].slug === 'gourmet' })
  let postsSpot = _.filter(posts, function (o) { return o.sightseeingCategories.nodes[0].slug === 'spot' })
  let postsEtc = _.filter(posts, function (o) { return o.sightseeingCategories.nodes[0].slug === 'etc' })

  return (
    <Layout isHomePage={true} headerClass="bg-white" isLogoOneWhite={false} isLogoTwoWhite={false}>
      <Seo title={seoTitle} description={seoDescription} />
      <main id="sightseeing-list">
        <h2><StaticImage src="../images/ttl-gourmet-spots@2x.png" alt="グルメ＆スポット" /></h2>
        <Tabs>
          <TabList className="swich-tab inner">
            <Tab>記事一覧</Tab>
            <Tab>グルメ</Tab>
            <Tab>スポット</Tab>
            <Tab>その他</Tab>
          </TabList>
          <TabPanel className="article-list inner">
            {Article(posts)}
          </TabPanel>
          <TabPanel className="article-list inner">
            {Article(postsGourmet)}
          </TabPanel>
          <TabPanel className="article-list inner">
            {Article(postsSpot)}
          </TabPanel>
          <TabPanel className="article-list inner">
            {Article(postsEtc)}
          </TabPanel>
        </Tabs>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpSightseeing(sort: { fields: [date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          uri
          title
          date(formatString: "YYYY. MM. DD")
          tanoshigeExternalUrl
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO]
                  )
                }
              }
            }
          }
          sightseeingCategories {
            nodes {
              id
              slug
            }
          }
        }
      }
    }
  }
`

export default SightseeingPage
